export function spin(elementId) {

    const luckWheel = document.getElementById(elementId);
    const timeAnimation = 10; // secs
    const spins = 6; // not alter
    const currentPosition = window.luckWheelSpinTimes || 1;
    const winner = Math.floor(Math.random() * 8)
    const messages = ["Pacific Holiday", 'A Free Coffee', 'A New Car!', 'Free Beer', 'Cash Prize', 'A New TV', 'Movie Tickets', 'A New MacBook'];


    const positions = [
        ((currentPosition * 360) * spins) + (370 - (45 * 1)),
        ((currentPosition * 360) * spins) + (370 - (45 * 2)),
        ((currentPosition * 360) * spins) + (370 - (45 * 3)),
        ((currentPosition * 360) * spins) + (370 - (45 * 4)),
        ((currentPosition * 360) * spins) + (370 - (45 * 5)),
        ((currentPosition * 360) * spins) + (370 - (45 * 6)),
        ((currentPosition * 360) * spins) + (370 - (45 * 7)),
        ((currentPosition * 360) * spins) + (370 - (45 * 8)),

    ]

    if (luckWheel) {
        luckWheel.style.setProperty('transition', `all ${timeAnimation - 5}s ease-in-out`)
        luckWheel.style.setProperty('transform', `rotate(${positions[winner] +18}deg)`)
        window.luckWheelSpinTimes ? window.luckWheelSpinTimes += 1 : window.luckWheelSpinTimes = 2
        setTimeout(() => {
            luckWheel.style.setProperty('transition', `all 2s ease-in-out`)
            luckWheel.style.setProperty('transform', `rotate(${positions[winner]-18}deg)`)
        }, ((timeAnimation - 5) * 1000));
        setTimeout(() => {
            luckWheel.style.setProperty('transition', `all 3s ease-in-out`)
            luckWheel.style.setProperty('transform', `rotate(${positions[winner]+3}deg)`)
        }, ((timeAnimation - 3) * 1000));
        setTimeout(() => {
            luckWheel.style.setProperty('transition', `all 2s ease-in-out`)
            luckWheel.style.setProperty('transform', `rotate(${positions[winner]}deg)`)
        }, ((timeAnimation) * 1000));
        return { winner: messages[winner], timeAnimation }



    } else return





}