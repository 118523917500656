export const slotData = {
    r0: ['Carrie', 'Kyle', 'Samantha', 'Cartman', 'Charlotte', 'Stan', 'Miranda', 'Kenny', 'Jill', 'Jack'],
    r1: ['is', 'knows', 'has', 'wants', 'needs', 'enjoys', 'likes', 'demands', 'gives', 'shows'],
    r2: ['danger', 'calm', 'surprise', 'delight', 'dancing', 'nothing', 'everything', 'action', 'freedom', 'kindness'],
    r3: [
        '/images/wordGenerator/3_0.png',
        '/images/wordGenerator/3_1.png',
        '/images/wordGenerator/3_2.png',
        '/images/wordGenerator/3_3.png',
        '/images/wordGenerator/3_4.png',
        '/images/wordGenerator/3_5.png',
        '/images/wordGenerator/3_6.png',
        '/images/wordGenerator/3_7.png',
        '/images/wordGenerator/3_8.png',
        '/images/wordGenerator/3_9.png',
    ],
    r4: [
        '/images/wordGenerator/4_0.png',
        '/images/wordGenerator/4_1.png',
        '/images/wordGenerator/4_2.png',
        '/images/wordGenerator/4_3.png',
        '/images/wordGenerator/4_4.png',
        '/images/wordGenerator/4_5.png',
        '/images/wordGenerator/4_6.png',
        '/images/wordGenerator/4_7.png',
        '/images/wordGenerator/4_8.png',
        '/images/wordGenerator/4_9.png',

    ],
}