import React, { useState, useEffect } from 'react';
import { slotData } from './slotData'
import './style.css'

function ReelSlot(props) {
    //animation conf 
    const reelSize = 200; // how many words we gonna have 
    const timeAnimation = props.timeAnimation || 5;

    const itemHeight = props.emoji ? (130 * 0.5) : (130 * 0.45); // do not alter!

    const [Content, setContent] = useState([])
    const [state, setState] = useState({
        spin: 0,
        position: `-${(reelSize * itemHeight) + itemHeight / 3}px`,
        time: timeAnimation,
        content: []
    })


    function defineWords() {
        const words = slotData[`r${props.reel || 0}`]
        const arrWords = []

        for (let i = 0; i < reelSize; i++) {
            let randomWord = words[(Math.random() * (words.length - 1)).toFixed(0)]
           
            while (randomWord === arrWords[i - 1]) {
                randomWord = words[(Math.random() * (words.length - 1)).toFixed(0)]
            }
            arrWords.push(randomWord)
        }

        let arr = []
        for (let i = 0; i < reelSize; i++) {
            arr.push(
                props.emoji ?
                    <div key={`reel-slot-aleatory-${i} - ${props.reel || 0}`}
                        className={`reel-slot-container-content-item-emoji ${i !== 1 ? `noWinners_${props.reel}` : ''}`}
                        style={{ backgroundImage: `url(${words[(Math.random() * (words.length - 1)).toFixed(0)]})` }}>

                    </div>
                    :
                    <div key={`reel-slot-aleatory-${i} - ${props.reel || 0}`} className={`reel-slot-container-content-item ${i !== 1 ? `noWinners_${props.reel}` : ''}`}>
                        <span>
                            {arrWords[i]}
                        </span>
                    </div>
            )
        }
        setContent(arr)
    }


    useEffect(() => {
        defineWords();
        // eslint-disable-next-line 
    }, [])



    const spin = () => {
        const rootReel = document.getElementById(`root-reel-${props.reel}`)
        const rootReelMainBox = document.getElementById(`root-reel-main-box-${props.reel}`)

        const root = document.documentElement;
        root.style.setProperty(`--noWinners-color_${props.reel}`, 'var(--default-red);');
        root.style.setProperty(`--noWinners-opacity_${props.reel}`, '1');
        rootReel.style.setProperty(`transform`, `scale(${props.scale * (props.mobile ? 1.8 : 1.09)})  translateZ(0.1px)  `);
        rootReel.style.setProperty(`margin-left`, `-${props.mobile ? 15 : 1}%`);
        rootReel.style.setProperty(`margin-top`, `-${props.mobile ? 15 : 1}%`);
        rootReel.style.setProperty(`z-index`, `999`);
        rootReel.style.setProperty(`transition`, `all 1s ease-in-out`);
        rootReelMainBox.style.setProperty(`box-shadow`, `0px 0px 0px 0px`);

        setState({ ...state, position: `-${itemHeight / 2}px`, time: `${timeAnimation - 2}.2s` })
        setTimeout(() => {
            setState({ ...state, position: `${itemHeight / 3}px`, time: '1s' })
        }, ((timeAnimation - 2) * 1000));
        setTimeout(() => {
            setState({ ...state, position: `-${itemHeight / 2}px`, time: '1.5s' })
        }, ((timeAnimation - 1) * 1000));
        setTimeout(() => {
            rootReel.style.setProperty(`transform`, `scale(${props.scale * 1})  translateZ(0.1px)  `);
            rootReel.style.setProperty(`margin-left`, `0%`);
            rootReel.style.setProperty(`margin-top`, `0%`);
            rootReel.style.setProperty(`z-index`, `0`);
            rootReelMainBox.style.setProperty(`transition`, `all 1s ease-in-out`);
            setTimeout(() => {
                rootReelMainBox.style.setProperty(`box-shadow`, `5px 5px 10px 2px #503a1d`);
            }, 100);



            root.style.setProperty(`--noWinners-color_${props.reel}`, '#0e1336');
            root.style.setProperty(`--noWinners-opacity_${props.reel}`, '0.3');

            onFinish()
        }, ((timeAnimation) * 1000));
    }

    function onFinish() {
        if (props.finishFunc) { props.finishFunc(1 + props.reel || 1) }
    }

    useEffect(() => {
        if (props.spin === 1) {
            spin();
        }
        if (props.spin === 999) {
            let root = document.documentElement;
            root.style.setProperty(`--noWinners-color_${props.reel}`, 'var(--default-red);');
            root.style.setProperty(`--noWinners-opacity_${props.reel}`, '0');

            setState({
                spin: 0,
                position: `-${(reelSize * itemHeight) + itemHeight / 3}px`,
                time: '0s',
                content: []
            }, defineWords())
        }

        // eslint-disable-next-line 
    }, [props.spin])



    return (
        <div id={`root-reel-${props.reel}`} style={{ position: 'absolute', transform: props.scale ? `scale(${props.scale})` : 'scale(1)' }}>
            <div id={`root-reel-main-box-${props.reel}`} className='reel-main-box '>
                <div className='reel-slot-container'  >
                    <div className='reel-slot-container-shadow'>
                        <div className='reel-slot-container-gloss'>
                            <div className='reel-slot-container-content'
                                style={{
                                    marginTop: state.position,
                                    transition: `margin ${state.time} ease-in-out`
                                }}
                            >

                                {Content.map(item => item)}
                                <div className='reel-slot-container-content-item'>
                                    <span></span>
                                </div>
                                <div className='reel-slot-container-content-item'>
                                    <span>
                                        {
                                            props.textInitial
                                        }</span>
                                </div>
                                <div className='reel-slot-container-content-item'>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReelSlot;