import React, { useState, createRef, useEffect } from 'react';
import '../styles/wordGenerator.css'
import ReeLSlot from '../../mainComponents/SlotReel'


function WordGenerator(props) {
    const [scale, setScale] = useState(0.5);
    const reelBoxRef = createRef()
    const [r0, setR0] = useState({ spin: 0, reel: 0, textInitial: 'the' })
    const [r1, setR1] = useState({ spin: 0, reel: 1, textInitial: 'button' })
    const [r2, setR2] = useState({ spin: 0, reel: 2, textInitial: 'to' })
    const [r3, setR3] = useState({ spin: 0, reel: 3, textInitial: 'use', emoji: true })
    const [r4, setR4] = useState({ spin: 0, reel: 4, textInitial: 'win', emoji: true })

    function startSpin() {
        setR0({ spin: 999, reel: 0, timeAnimation: 0, textInitial: 'the' })
        setR1({ spin: 999, reel: 1, timeAnimation: 0, textInitial: 'button' })
        setR2({ spin: 999, reel: 2, timeAnimation: 0, textInitial: 'to' })
        setR3({ spin: 999, reel: 3, timeAnimation: 0, textInitial: 'use', emoji: true })
        setR4({ spin: 999, reel: 4, timeAnimation: 0, textInitial: 'win', emoji: true })
        setTimeout(() => {

            playButtonAnimation.animationPause()
            setR3({ spin: 1, reel: 3, timeAnimation: 3, emoji: true })
            setR0({ spin: 1, reel: 0, timeAnimation: 4 })
            setR1({ spin: 1, reel: 1, timeAnimation: 5 })
            setR2({ spin: 1, reel: 2, timeAnimation: 6 })
            setR4({ spin: 1, reel: 4, timeAnimation: 7, emoji: true })
        }, 100);
    }

    function nextReel(reel) {
        if (reel === 5) {
            setTimeout(() => {
                playButtonAnimation.animationPlay()
            }, 2000);
        }
    }

    useEffect(() => {
        if (reelBoxRef.current) {
            setScale((reelBoxRef.current.clientWidth) / 200)

        }

        // eslint-disable-next-line 
    }, [reelBoxRef])

    const playButtonAnimation = {
        animationPlay: () => {
            document.getElementById('word-generator-play-button').style.setProperty('animation-name', 'shakeUp')
            document.getElementById('word-generator-play-button').style.setProperty('opacity', '1')

        },
        animationPause: () => {
            document.getElementById('word-generator-play-button').style.setProperty('animation-name', 'none')
            document.getElementById('word-generator-play-button').style.setProperty('opacity', '.8')
        },
    }



    return (
        <div className='ticket-section-word-generator-box'>
            <div className=' word-generator-ticket-frame  container' >

                <div className='word-generator-ticket-frame-stripes' style={{ height: `${((160 * 3) * scale)}px` }}>
                    <div className='word-generator-ticket-frame-wrap' style={{ height: `${((160 * 3) * scale)}px` }}>
                        <div className='word-generator-ticket-title ' style={{ width: '100%' }}>
                            Ticket
                        </div>
                        <div className='word-generator-ticket-number ' style={{ width: '10%' }}>
                            Nº 12345
                        </div>
                        <div className='word-generator-body-reel-container '
                            style={{ width: '85%', marginLeft: '2.5%' }}>

                            <div ref={reelBoxRef} className=' reel-box'>
                                <ReeLSlot {...r3} scale={scale} finishFunc={nextReel} />

                            </div>
                            <div className=' reel-box'>
                                <ReeLSlot {...r0} scale={scale} finishFunc={nextReel} />

                            </div>
                            <div className=' reel-box'>
                                <ReeLSlot {...r1} scale={scale} finishFunc={nextReel} />

                            </div>
                            <div className=' reel-box'>
                                <ReeLSlot {...r2} scale={scale} finishFunc={nextReel} />

                            </div>
                            <div className=' reel-box'>
                                <ReeLSlot {...r4} scale={scale} finishFunc={nextReel} />

                            </div>

                        </div>
                        <div className='word-generator-ticket-stars' style={{ width: '100%' }}>
                            &#9733;&#9733;&#9733;&#9733;&#9733;
                        </div>
                    </div>
                </div>
            </div>
            <div className='word-generator-button-container '>
                <div id='word-generator-play-button' onClick={() => { startSpin() }} className='word-generator-play-button'>
                    <div className='word-generator-text-button'>
                        <span>{props.translate('word.generator.spin.button.text')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WordGenerator;