import React, { useEffect, useState } from 'react';
import '../styles/playerSection.css'
import lottie from 'lottie-web'

import { Markup } from 'interweave';


function PlayerSection(props) {


    const [scrollPosition, setScrollPosition] = useState(0)

    useEffect(() => {
        window.addEventListener("scroll", setScroll);
        // eslint-disable-next-line 
    }, [])


    function setScroll() {
        let cardExample = document.querySelector('#player-card-section-home')

        if (cardExample && cardExample.getBoundingClientRect().top < (window.innerHeight) && !scrollPosition) {
            setScrollPosition(1)

        }
    }


    useEffect(() => {
        if (scrollPosition) {
            lottie.loadAnimation({
                container: document.getElementById(`lottie-box-home-player-image`),
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: '/lottie/register.json',

            });
        }
        // eslint-disable-next-line 
    }, [scrollPosition])

    return (
        <div id='player-card-section-home' className={` container card custom-card player-card-section-home-box `}>
            <div className='row player-card-section-home-box-row'>
                <div id='lottie-box-home-player-image' className=' col-12 col-md-4'>

                </div>

                <div className='col-12 col-md-8  player-card-section-home-text-box-wrap' style={{ position: 'relative' }}>
                    <div className='player-card-section-home-text-box'>
                        <h1> {props.translate('home.player.title')}</h1>
                        <Markup content={props.translate('home.player.lines')} />
                    </div>
                </div>
            </div>
        </div>

    );
}

export default PlayerSection;