import React, { useEffect, useState } from 'react';
import lottie from 'lottie-web'
import { Markup } from 'interweave';
import '../styles/complianceSection.css'
function ComplianceSection(props) {


    const [scrollPosition, setScrollPosition] = useState(0)

    useEffect(() => {
        window.addEventListener("scroll", setScroll);
        // eslint-disable-next-line 
    }, [])
    useEffect(() => {

        // eslint-disable-next-line 
    }, [])



    function setScroll() {
        let cardExample = document.querySelector('#compliance-card-section-home')

        if (cardExample && cardExample.getBoundingClientRect().top < (window.innerHeight) && !scrollPosition) {
            setScrollPosition(1)

        }
    }


    useEffect(() => {
        if (scrollPosition) {
            lottie.loadAnimation({
                name: 'compliances',
                container: document.getElementById(`lottie-box-home-compliance-image`),
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: '/lottie/compliance1.json',

            });
        }
        // eslint-disable-next-line 
    }, [scrollPosition])



    return (
        <div className=''>
            <div id='compliance-card-section-home' className={`container card custom-card-compliance `}>
                <div className='row'>
                    <div className='compliance-section-card-header'>
                        <h1> {props.translate('home.compliance.title')}</h1>
                    </div>
                </div>
                <div className='col-12 '>
                    <div className=' row '>
                        <div className='d-flex col-12 col-md-3 d-flex compliance-image-wrap  order-last order-md-last' >
                            <div className='col-12'><div className='compliance-image-3' /></div>
                            <div className='col-12'><div className='compliance-image-1' /></div>
                            <div className='col-12'><div className='compliance-image-4' /></div>
                            <div className='col-12'><div className='compliance-image-2' /></div>
                        </div>
                        <div className='d-flex col-12 col-md-9 order-first order-md-last compliance-section-text-box'>
                            <Markup content={props.translate('home.compliance.lines')} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 compliance-acronyms' >
                        <Markup content={props.translate('home.compliance.acronyms')} />
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ComplianceSection;