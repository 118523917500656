import React, { useState, createRef, useEffect } from 'react';
import '../styles/wordGeneratorMobile.css'
import ReeLSlot from '../../mainComponents/SlotReel'


function WordGeneratorMobile(props) {
    const [scale, setScale] = useState(0.5);
    const reelBoxRef = createRef()
    const [r0, setR0] = useState({ spin: 0, reel: 0, textInitial: 'use', mobile: true })
    const [r1, setR1] = useState({ spin: 0, reel: 1, textInitial: 'the', mobile: true })
    const [r2, setR2] = useState({ spin: 0, reel: 2, textInitial: 'button', mobile: true })
    const [r3, setR3] = useState({ spin: 0, reel: 3, textInitial: 'to', emoji: true, mobile: true })
    const [r4, setR4] = useState({ spin: 0, reel: 4, textInitial: 'win', emoji: true, mobile: true })


    function startSpin() {

        setR0({ spin: 999, reel: 0, timeAnimation: 0, textInitial: '', mobile: true })
        setR1({ spin: 999, reel: 1, timeAnimation: 0, textInitial: '', mobile: true })
        setR2({ spin: 999, reel: 2, timeAnimation: 0, textInitial: '', mobile: true })
        setR3({ spin: 999, reel: 3, timeAnimation: 0, textInitial: '', emoji: true, mobile: true })
        setR4({ spin: 999, reel: 4, timeAnimation: 0, textInitial: '', emoji: true, mobile: true })
        setTimeout(() => {
            playButtonAnimation.animationPause()
            setR0({ spin: 1, reel: 0, timeAnimation: 2, mobile: true })
        }, 100);
    }

    function nextReel(reel) {
        if (reel < 5) {
            switch (reel) {
                case 1:
                    return setR1({ spin: 1, reel: 1, timeAnimation: 2, mobile: true })
                case 2:
                    return setR2({ spin: 1, reel: 2, timeAnimation: 2, mobile: true })
                case 3:
                    return setR3({ spin: 1, reel: 3, timeAnimation: 2, emoji: true, mobile: true })
                case 4:
                    return setR4({ spin: 1, reel: 4, timeAnimation: 2, emoji: true, mobile: true })
                default:
                    return

            }
        }
        else if (reel === 5) {
            setTimeout(() => {
                playButtonAnimation.animationPlay()

            }, 4000);
        }
    }

    useEffect(() => {
        if (reelBoxRef.current) {
            setScale((reelBoxRef.current.clientWidth / 200))
        }

        // eslint-disable-next-line 
    }, [reelBoxRef])

    const playButtonAnimation = {
        animationPlay: () => {
            document.getElementById('word-generator-play-button').style.setProperty('animation-name', 'shakeUp')
            document.getElementById('word-generator-play-button').style.setProperty('opacity', '1')

        },
        animationPause: () => {
            document.getElementById('word-generator-play-button').style.setProperty('animation-name', 'none')
            document.getElementById('word-generator-play-button').style.setProperty('opacity', '.8')
        },
    }
    return (
        <div className='ticket-section-word-generator-mobile-box'>
            <div className='word-generator-mobile-header-section'>

            </div>
            <div className=' word-generator-mobile-ticket-frame ' style={{ height: `${800 * scale}px` }}>

                <div className='word-generator-mobile-ticket-frame-wrap' >
                    <div className='word-generator-mobile-ticket-title ' style={{ width: '100%' }}>
                        Ticket
                        </div>
                    <div className='word-generator-mobile-ticket-number ' style={{ width: '10%' }}>
                        Nº 12345
                        </div>







                    <div className='word-generator-mobile-body-reel-container '
                        style={{ width: '93%', marginLeft: '5%', height: `${(150 * 2) * scale}px` }}>
                        <div className='word-generator-mobile-body-reel-container-line-1 ' style={{ height: `${150 * scale}px` }}>
                            <div ref={reelBoxRef} className=' reel-box-mobile'>
                                <ReeLSlot {...r0} scale={scale} finishFunc={nextReel} />
                            </div>
                            <div className=' reel-box-mobile'>
                                <ReeLSlot {...r1} scale={scale} finishFunc={nextReel} />
                            </div>
                            <div className=' reel-box-mobile'>
                                <ReeLSlot {...r2} scale={scale} finishFunc={nextReel} />
                            </div>
                        </div>
                        <div className='word-generator-mobile-body-reel-container-line-2' style={{ height: `${150 * scale}px` }}>
                            <div className=' reel-box-mobile-line-2'>
                                <ReeLSlot {...r3} scale={scale} finishFunc={nextReel} />
                            </div>
                            <div className=' reel-box-mobile-line-2'>
                                <ReeLSlot {...r4} scale={scale} finishFunc={nextReel} />
                            </div>

                        </div>
                    </div>





                    <div className='word-generator-mobile-ticket-stars' style={{ width: '100%' }}>
                        &#9733;&#9733;&#9733;&#9733;&#9733;
                        </div>
                </div>
            </div>
            <div className='word-generator-mobile-button-container '>
                <div id='word-generator-play-button' onClick={() => { startSpin() }} className='word-generator-mobile-play-button'>
                    <div className='word-generator-mobile-text-button'>
                        <span>{props.translate('word.generator.spin.button.text')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WordGeneratorMobile;