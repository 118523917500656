import React from 'react';
import '../styles/introSection.css'

function IntroSection(props) {
    return (
        <div className='container' style={{ position: "relative" }}>
            <div class='custom-sub-card intro-card' >
                <div class='custom-sub-card-header intro-card-header'>

                    <h3 style={{ color: '#fff' }} >{props.translate('home.intro.title')}</h3>
                </div>
                <div class='custom-sub-card-content intro-card-content'>
                    <p >{props.translate('home.intro.text1')}</p>
                    <p style={{ color: 'var(--default-red' }}>
                            {props.translate('home.intro.text2')}
                      
                    </p>
                    <p ><b style={{color:"#333"}}>{props.translate('home.intro.summary')}</b></p>
                </div>
            </div>
        </div>
    );
}

export default IntroSection;