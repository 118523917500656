import React, { useEffect } from 'react';
import '../styles/heroBanner.css'
import lottie from 'lottie-web'


function HeroBanner(props) {

    useEffect(() => {

        lottie.loadAnimation({
            name: 'tickets',
            container: document.getElementById(`lottie-box-home-down-arrow`),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/lottie/down.json',

        });


    }, [])

    return (
        <div className='hero-banner-box'>
            <div className='hero-banner-content hero-banner-content-background-blink ' >
                <div className='hero-banner-content-text-box fade-moving-left'>
                    <div className='hero-banner-content-text-box-wrap'>
                        <h1>{props.translate('home.hero.banner.title')}</h1>
                        <h3>{props.translate('home.hero.banner.subtitle')}</h3>
                    </div>
                    <div onClick={() => { document.getElementById('under-banner-section').scrollIntoView({ behavior: 'smooth' }); }} className="col-12 hero-banner-link">

                        <div className="d-flex justify-content-center"  >
                            <div className='col-6 start-the-journey-text d-flex justify-content-center '>
                                <h3>
                                    {props.translate('home.hero.banner.button.text')}

                                </h3>
                                <div id='lottie-box-home-down-arrow' className='col-1' >

                                </div>
                            </div>
                        </div>

                        {/* <button  className="btn btn-danger btn-lg" type="button">
                                </button> */}
                    </div>

                </div>
            </div>
        </div>
    );
}

export default HeroBanner;