import React, { useEffect, useState } from 'react';
import '../styles/reportSection.css'
import lottie from 'lottie-web'
import { Markup } from 'interweave';




function ReportSection(props) {


    const [scrollPosition, setScrollPosition] = useState(0)

    useEffect(() => {
        window.addEventListener("scroll", setScroll);
        // eslint-disable-next-line 
    }, [])


    function setScroll() {
        let cardExample = document.querySelector('#report-card-section-home')

        if (cardExample && cardExample.getBoundingClientRect().top < (window.innerHeight + 10) && !scrollPosition) {
            setScrollPosition(1)

        }
    }


    useEffect(() => {
        if (scrollPosition) {
            lottie.loadAnimation({
                container: document.getElementById(`lottie-box-home-report-image`),
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: '/lottie/report.json',

            });
        }
        // eslint-disable-next-line 
    }, [scrollPosition])


    return (
        <div className='report-card'>
            <div id='report-card-section-home' className={` container card report-card-section-home-box custom-card `}>
                <div className=' report-card-section-home-box-row'>
                    <div className=' col col-md-4' style={{position:"relative"}}>
    
                        <div id='lottie-box-home-report-image' className=' col-md-10'>
    
    
                        </div>
                    </div>
                    <div className='col-12 col-md-8 ' style={{ position: 'relative' }}>
                        <div className='report-card-section-home-text-box'>
                        <h1> {props.translate('home.report.title')}</h1>
                                <Markup content={props.translate('home.report.lines')} />

    
    
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ReportSection;