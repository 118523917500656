import React, { useEffect } from 'react';
import Home from '../home'
import NotFound from '../404/'
import Translate, { getTranslate } from './translator/'

import { installGtag, gtag } from 'mr-gtag';


export const RouteContext = React.createContext()
function PageRouteContent(props) {
    const translate = (key) => getTranslate(props.location, key)
    const content = (props.match.params.content || 'home').toString().toLowerCase()


    const GA_KEY = 'G-WT4TVER4GF'
    installGtag(GA_KEY);

    useEffect(() => {
        gtag('event', 'page_view',
            {
                'page_title': content,
                'page_path': window.location.pathname + window.location.search
            }
        )

    }, [content])


    const Contents = {
        'home': <Home translate={translate} />,
        'not-found': <NotFound translate={translate} />
    }
    return (
        <RouteContext.Provider value={{ Translate, translate }}>
            {Contents[content] || Contents["not-found"]}
        </RouteContext.Provider>
    );

}

export default PageRouteContent;