import React from 'react';
import './styles/general.css'
function Footer(props) {
    return (
        <div className='footer'>
            <footer id="footer" className=" modern">
                <div className="container">
                    <div className="row d-flex justify-content">
                        <div className="col-12 col-md-3">
                            <div className="footer-logo">
                                <img alt="Newacy Consulting" className=" lazyloaded" src="/logos/tom-bola-footer.png" style={{ width: "50%" }} />
                                <a href='https://newacy.com/'>
                                    <img alt="Newacy Consulting" className=" lazyloaded" src="/logos/newacy-footer.png" style={{ width: "50%" }} />
                                </a>
                            </div>
                        </div>

                    </div>
                    <div className="footer-bottom-wrap">
                        <div className="container">
                            <div className='col-12'>
                                <a href='https://newacy.com/'>© Newacy Limited 2021.</a>
                            </div>
                        </div>
                        <div className='col-12'>
                            <p><a href="mailto:sales@newacy.com">sales@newacy.com</a></p>

                        </div>
                        <div className="col-12">
                            <div className="socials">
                                {// eslint-disable-next-line
                                    <a href="https://www.linkedin.com/company/newacy-limited" className="fa fa-linkedin" />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </div >
    );
}

export default Footer;