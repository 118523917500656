import React,{useEffect, useState} from 'react';
import WordGenerator from './WordGenerator';
import WordGeneratorMobile from './WordGeneratorMobile';
function WordGeneratorSection(props) {
    console.log('props: ', props);
    
        const [w, setW] = useState(window.innerWidth);
      useEffect(() => {
            
            window.addEventListener('resize', _setW)
            // eslint-disable-next-line 
        }, [])
     let resizeF = null;
    
        function _setW() {
            clearTimeout(resizeF);
            resizeF = setTimeout(() => {
                setW(window.innerWidth);
            }, 500);
        }
    return (
        <div className='col-12' >
        {
            w < 768 ?
                <WordGeneratorMobile {...props} /> :
                <WordGenerator {...props}  />
        }
    </div>
    );
}

export default WordGeneratorSection;