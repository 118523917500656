import React, { useEffect, useState } from 'react';
import lottie from 'lottie-web'
import { Markup } from 'interweave';


import '../styles/ticketSection.css'

function TicketSection(props) {

    const [w, setW] = useState(window.innerWidth);

    const [scrollPosition, setScrollPosition] = useState(0)

    useEffect(() => {
        window.addEventListener("scroll", setScroll);
        window.addEventListener('resize', _setW)
        // eslint-disable-next-line 
    }, [])
    useEffect(() => {

        // eslint-disable-next-line 
    }, [])

    let resizeF = null;

    function _setW() {
        clearTimeout(resizeF);
        resizeF = setTimeout(() => {
            setW(window.innerWidth);
        }, 500);
    }

    function setScroll() {
        let cardExample = document.querySelector('#ticket-card-section-home')

        if (cardExample && cardExample.getBoundingClientRect().top < (window.innerHeight) && !scrollPosition) {
            setScrollPosition(1)

        }
    }


    useEffect(() => {
        if (scrollPosition) {
            lottie.loadAnimation({
                name: 'tickets',
                container: document.getElementById(`lottie-box-home-ticket-image`),
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: '/lottie/ticket.json',

            });
        }
        // eslint-disable-next-line 
    }, [scrollPosition])

    useEffect(() => {
        lottie.destroy('lottie-box-home-word-generator-play-button');
        lottie.loadAnimation({
            name: 'lottie-box-home-word-generator-play-button',
            container: document.getElementById(`lottie-box-home-word-generator-play-button`),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/lottie/playButton.json',
        })
    }, [w])


    return (
        <div className=''>
            <div id='ticket-card-section-home' className={`container card custom-card-ticket`}>
                <div className='row'>
                    <div className='ticket-section-card-header'>
                        <h1> {props.translate('home.tickets.title')}</h1>
                        <div id='lottie-box-home-ticket-image' className=' ticket-card-animation '>

                        </div>
                    </div>
                </div>
                <div className=''>
                    <div className='col-12 ticket-section-text-box'>
                        <div className='d-flex justify-content-center'>
                            <div className='col-12 col-md-10'>
                                <Markup content={props.translate('home.tickets.lines')} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </div>
    );
}

export default TicketSection;