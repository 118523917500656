import React from 'react';
import Header from '../header'
import Footer from '../footer'

function PageFrame(props) {
    return (
        <>
            <div hidden={props.hiddenHeader}>
                <Header />
            </div>
            {props.children}
            <div hidden={props.hiddenFooter}>
                <Footer />
            </div>

        </>
    );
}

export default PageFrame;