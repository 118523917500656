import { createI18n } from 'react-router-i18n';
import dot from 'dot-object'
import en from '../../../translations/en.json'
import da from '../../../translations/da.json'
// Array of supported locales
// The first in the array is treated as the default locale 

const locales = ['en', 'da'];

// Dictionary of translations
const translations = {
    en: { ...dot.object(en) },
    da: { ...dot.object(en), ...dot.object(da) }, //this make 'en' like default
}

const Translate = createI18n(
    locales,
    translations,
);

export default Translate;
export const getTranslate = (location, key) => Translate.getTranslation(location, key)
