import React, { useEffect, useState } from 'react';
import '../styles/partnersSection.css'

function PartnerSection(props) {

    const [scrollPosition, setScrollPosition] = useState(0)

    useEffect(() => {
        window.addEventListener("scroll", setScroll);
        // eslint-disable-next-line 
    }, [])


    function setScroll() {
        let section = document.querySelector('#tom-bola-partners')

        if (section && section.getBoundingClientRect().top < (window.innerHeight) && !scrollPosition) {
            setScrollPosition(1)
        }
        else if (section && section.getBoundingClientRect().top >= (window.innerHeight)) {
            setScrollPosition(0)
        }
    }
    return (
        <div id='tom-bola-partners' >
            <div  className='tom-bola-partners-wrap' >

                <div className={`tom-bola-partners-hero-banner-section ${scrollPosition ? '' : 'tom-bola-partners-hero-banner-section-hidden'} `}>
                    <div className='container'>

                        <div className='tom-bola-partners-hero-banner-section-content'>
                            <h1> {props.translate('home.partners.title')}  </h1>
                            <div className='d-flex justify-content-center'>

                            <div className='col-10 col-md-8 col-lg-6 col-xl-6'>
                                <a href="https://www.ukcharityweek.co.uk/" target="_blank" rel="noopener noreferrer">
                                    <img width='100%' src="/images/partner.png" alt="" />
                                </a>
                            </div>
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
}

export default PartnerSection;
