import React from 'react';
import HeroBanner from './components/HeroBanner'
import PageFrame from '../mainComponents/pageFrame'
import PartnerSection from './components/PartnerSection';
import FullTape from './components/FullTape'
import PlayerSection from './components/PlayerSection'
import PaymentsSection from './components/PaymentsSection'

import './styles/home.css'
import TicketSection from './components/TicketSection';
import ExtraSection from './components/ExtraSection';
import ReportSection from './components/ReportSection';
import ComplianceSection from './components/Compliance';
import ContactSection from './components/ContactUs';
import WordGeneratorSection from './components/WordGeneratorSection'
import IntroSection from './components/IntroSection'
function Home(props) {


    return (
        <PageFrame>
            <HeroBanner  {...props} />
            <div className='col-12'>
                <PartnerSection {...props} />
                <FullTape {...props} />
                <IntroSection {...props} /> 
                <div className='container home-pre-sections-title'>
                    <div className='d-flex justify-content-center'>
                        <div className='col-8 col-md-4 col-lg-3'>
                            <img src="/logos/tom-bola.svg" width='100%' alt="" />
                        </div>

                    </div>
                    <h1 className='h1-blue'> {props.translate('home.pre.sections.title.blue')}
                    </h1>
                    <h1 className='h1-red'>{props.translate('home.pre.sections.title.red')}</h1>.
                </div>
                <PlayerSection {...props} />
                <TicketSection {...props} />
                <PaymentsSection {...props} />
                <WordGeneratorSection {...props} />
                <ExtraSection {...props} />
                <ReportSection {...props} />
                <ComplianceSection {...props} />
                <ContactSection {...props} />
                <div className='thank-you-section'>
                    <h1>{props.translate('home.thank.you.section.text')}</h1>
                </div>
            </div>
        </PageFrame>
    );
}

export default Home;