import React, { useState, useEffect } from 'react';
import '../styles/extraSection.css'
import LuckyWheel from '../../mainComponents/LuckyWheel';
import { Markup } from 'interweave';

function ExtraSection(props) {

    const [scrollPosition, setScrollPosition] = useState(0)

    useEffect(() => {
        window.addEventListener("scroll", setScroll);
        // eslint-disable-next-line 
    }, [])



    useEffect(() => {
        if (scrollPosition) {


        }
        // eslint-disable-next-line 
    }, [scrollPosition])


    function setScroll() {
        let element = document.querySelector('#extra-section')

        if (element && element.getBoundingClientRect().top < (window.innerHeight) && !scrollPosition) {
            setScrollPosition(1)
        }
    }


    return (
        <div className='full-tape-box'>
            <div id='extra-section' className={`container extra-section-container`} >
                <div className='extra-section-header'>
                </div>
                <div className='extra-section-body'>
                    <div className='col-12 col-md-4'>
                        <LuckyWheel {...props} />
                    </div>
                    <div className='col-12 offset-md-1 col-md-7 extra-section-body-text'>
                        <h1> {props.translate('home.extras.title')}</h1>
                        <Markup content={props.translate('home.extras.lines')} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExtraSection;