import React, { useEffect, useState } from 'react';
import '../styles/paymentsSection.css'
import lottie from 'lottie-web'
import { Markup } from 'interweave';



function PaymentsSection(props) {


    const [scrollPosition, setScrollPosition] = useState(0)

    useEffect(() => {
        window.addEventListener("scroll", setScroll);
        // eslint-disable-next-line 
    }, [])


    function setScroll() {
        let cardExample = document.querySelector('#payment-card-section-home')

        if (cardExample && cardExample.getBoundingClientRect().top < (window.innerHeight + 10) && !scrollPosition) {
            setScrollPosition(1)

        }
    }


    useEffect(() => {
        if (scrollPosition) {
            lottie.loadAnimation({
                container: document.getElementById(`lottie-box-home-payment-image`),
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: '/lottie/payment.json',

            });
        }
        // eslint-disable-next-line 
    }, [scrollPosition])


    return (
        <div id='payment-card-section-home' className={` container card custom-card-right-side payment-card-section-home-box `}>
            <div className='row payment-card-section-home-box-row'>

                <div className='col col-sm-12 col-md-8  payment-card-section-home-text-box-wrap' style={{ position: 'relative' }}>
                    <div className='payment-card-section-home-text-box'>
                        <h1> {props.translate('home.payment.title')}</h1>
                        <Markup content={props.translate('home.payment.lines')} />
                    </div>
                </div>
                <div className='d-flex  col-12  col-md-4 order-first order-md-last'>

                    <div id='lottie-box-home-payment-image' className=' col-12 offset-md-2 col-md-10'>


                    </div>
                </div>
            </div>
        </div>

    );
}

export default PaymentsSection;