import React, { useState, useEffect } from 'react';
import '../styles/fullTape.css'
import lottie from 'lottie-web'
function FullTape(props) {

    const [scrollPosition, setScrollPosition] = useState(0)

    useEffect(() => {
        window.addEventListener("scroll", setScroll);
        // eslint-disable-next-line 
    }, [])



    const animations = [
        './lottie/register.json',
        './lottie/ticket.json',
        './lottie/winner.json',
    ]
    const lottieConfig = {
        container: null,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: null
    }

    useEffect(() => {
        if (scrollPosition) {
            lottie.loadAnimation({
                ...lottieConfig,
                container: document.getElementById(`lottie-box-home-0`),
                path: animations[0]
            });
            lottie.loadAnimation({
                ...lottieConfig,
                container: document.getElementById(`lottie-box-home-1`),
                path: animations[1]
            });
            lottie.loadAnimation({
                ...lottieConfig,
                container: document.getElementById(`lottie-box-home-2`),
                path: animations[2]
            });

        }
        // eslint-disable-next-line 
    }, [scrollPosition])


    function setScroll() {
        let cardExample = document.querySelector('#full-left-tape-1')

        if (cardExample && (cardExample.getBoundingClientRect().top - 100) < (window.innerHeight) && !scrollPosition) {
            setScrollPosition(1)

        }
    }


    return (
        <div id='under-banner-section' className='full-tape-box'>
            <div id='full-left-tape-1' className={`container`}>
                <div className={`row`}>
                        <div className='col-12 col-md-10 offset-md-1 d-flex'>
                            <div className='col-4'>

                                <div id={`lottie-box-home-0`} className='full-left-tape-img-content'>
                                </div>

                            </div>
                            <div className='col-4' style={{ paddingTop: `20px` }}>

                                <div id={`lottie-box-home-1`} className='full-left-tape-img-content'>
                                </div>

                            </div>
                            <div className=' col-4'>

                                <div id={`lottie-box-home-2`} className='full-left-tape-img-content'>
                                </div>

                            </div>
                        </div>
                   
                </div>
            </div>
        </div>
    );
}

export default FullTape;