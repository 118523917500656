import React, { useState, createRef, useEffect } from 'react';
import { spin } from './spin'
import './styles/general.css'
function LuckyWheel(props) {
    const [size, setSize] = useState(500)
    const wheelBox = createRef()

    useEffect(() => {
        if (wheelBox.current) {
            setSize(wheelBox.current.clientWidth)
        }
    }, [wheelBox])

    const [message, setMessage] = useState('')
    function _spin() {

        const { winner, timeAnimation } = spin('pie-spin-lw')
        setTimeout(() => {
            setMessage(winner)
        }, (timeAnimation - 2) * 1000);
        setTimeout(() => {
            setMessage('')
        }, (timeAnimation * 1000) + 5000);
    }

    return (
        <div className='col-12'>

            <div ref={wheelBox} className='' style={{ position: 'relative' }}>
                <div className='selector-lucky-wheel'>
                    &#9660;
                    </div>
                <div id='pie-spin-lw' className="pie pie-spin"
                    style={{
                        "--size": size,
                    }} >
                    <div className="pie__segment" style={{ "--offset": 0, "--value": 12.5, "--bg": "url('/images/luckyWheel/b_0.png')", "--bgc": "var(--default-blue)" }} />
                    <div className="pie__segment" style={{ "--offset": 12.5, "--value": 12.5, "--bg": "url('/images/luckyWheel/b_1.png')", "--bgc": "var(--default-red)" }} />
                    <div className="pie__segment" style={{ "--offset": 25, "--value": 12.5, "--bg": "url('/images/luckyWheel/b_2.png')", "--bgc": "var(--default-blue)" }} />
                    <div className="pie__segment" style={{ "--offset": 37.5, "--value": 12.5, "--bg": "url('/images/luckyWheel/b_3.png')", "--bgc": "var(--default-red)" }} />
                    <div className="pie__segment" style={{ "--offset": 50, "--value": 12.5, "--bg": "url('/images/luckyWheel/b_4.png')", "--bgc": "var(--default-blue)" }} />
                    <div className="pie__segment" style={{ "--offset": 62.5, "--value": 12.5, "--bg": "url('/images/luckyWheel/b_5.png')", "--bgc": "var(--default-red)" }} />
                    <div className="pie__segment" style={{ "--offset": 75, "--value": 12.5, "--bg": "url('/images/luckyWheel/b_6.png')", "--bgc": "var(--default-blue)" }} />
                    <div className="pie__segment" style={{ "--offset": 87.5, "--value": 12.5, "--bg": "url('/images/luckyWheel/b_7.png')", "--bgc": "var(--default-red)" }} />

                </div>

                <div className='lucky-wheel-winner-text'>
                    <span>
                        {message}
                    </span>
                </div>
                <div className="d-grid gap-2 btn-lg box-btn-spin-lucky-wheel">
                    <button onClick={() => { _spin() }} className="btn btn-danger btn-lg btn-spin-lucky-wheel" type="button">Spin</button>
                </div>
            </div>
        </div>
    );
}

export default LuckyWheel;